<template>
  <!-- 申请退货 -->
  <div class="addPerson__wrap" v-show="visible">
    <el-header class="shadow page-header__wrap">
      <div class="flex">
        <div class="backBtn" @click="handleReturn">
          <el-image class="back-img" :src="require('@/assets/img/back.png')"></el-image>
          <span>返回</span>
        </div>
        <div class="title">
          <span class="site">当前位置：</span>
          {{ $route.meta.title }}
          <span> > </span>
          申请退货
        </div>
      </div>
    </el-header>

    <div class="main">
      <div class="mainBox">
        <div class="itemTime">基础信息</div>
        <el-descriptions title="" >
          <el-descriptions-item label="采购来源">
            <span>{{info.source == 1 ? '销售订单' : '平台自采'}}</span>
          </el-descriptions-item>
          <el-descriptions-item label="计划交货日期">{{info.delivery_date}}</el-descriptions-item>
          <el-descriptions-item label="创建时间">{{info.create_time}}</el-descriptions-item>
          <el-descriptions-item label="采购单号">{{info.order_no}}</el-descriptions-item>
          <el-descriptions-item label="采购状态">{{ toStr({0:'待发货',1:'待收货', 2:'已取消', 3:'部分收货', 4:'全部收货'}, info.status)}}</el-descriptions-item>
          <el-descriptions-item label="地区">{{info.city_name}}</el-descriptions-item>
          <el-descriptions-item label="仓库名称" v-if="!!info.stash_name">{{info.stash_name}}（{{info.stash_province}}{{info.stash_city}}）</el-descriptions-item>
          <el-descriptions-item label="供应商/采购员" v-if="!!info.supplier_name">{{info.supplier_name}}</el-descriptions-item>
          <el-descriptions-item label="发货时间" v-if="!!info.send_time">{{info.send_time}}</el-descriptions-item>
          <el-descriptions-item label="已收货数量" v-if="!!info.delivery_num && info.delivery_num > 0">{{info.delivery_num}}</el-descriptions-item>
          <el-descriptions-item label="已收货金额" v-if="!!info.delivery_money && info.delivery_money > 0">￥{{info.delivery_money}}</el-descriptions-item>
        </el-descriptions>

        <div class="itemTime">收货<span style="color:#999;font-size:14px;"> (共{{table.total}}项)</span></div>
        <el-input
          style="width:240px;margin-bottom:10px;"
          v-model.trim="form.data.goods_name"
          placeholder="请输入商品名称"
          size="small"
          clearable
          @clear="getTable"
          @keydown.enter.native="getTable"
        >
          <el-button slot="append" icon="el-icon-search" @click="getTable"></el-button>
        </el-input>

        <el-table
          :data="tableData"
          style="width: 100%"
          max-height="450"
          border
          :cell-style="{background:'#fff'}"
          :span-method="objectSpanMethod"
          @selection-change="handleSelectionChange">
          <el-table-column
            type="selection"
            width="55"
            :selectable="selectEnable" fixed>
          </el-table-column>
          <el-table-column type="index" label="ID" fixed></el-table-column>
          <el-table-column
            prop="good_img"
            label="商品名称"
            width="180px"
            fixed
            key="1">
            <template slot-scope="{row}">
              <div style="display: flex;align-items: center;">
                <el-image
                  v-if="!!row.goods_image"
                  style="width: 40px; height: 40px"
                  :src="row.goods_image"
                  fit="cover"
                  :preview-src-list="[row.goods_image]"></el-image>
                <el-image
                  v-else
                  style="width: 40px; height: 30px"
                  :src="require('@/assets/img/logo-icon.jpeg')"
                  fit="cover"
                  :preview-src-list="[require('@/assets/img/logo-icon.jpeg')]"></el-image>
                <span style="margin-left:10px;">{{row.goods_name}}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="code"
            label="商品编码"
            width="120px"
            key="2">
          </el-table-column>
          <el-table-column
            prop="order_cate_name"
            label="下单分类"
            key="3"
            min-width="120px">
            <template slot-scope="{row}">
              <span>{{row.order_cate_name}}</span>
              <span v-if="!!row.order_tow_name"> / {{row.order_tow_name}}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="unit_name"
            label="单位"
            key="4">
          </el-table-column>
          <el-table-column
            prop="spec_name"
            label="规格"
            key="5">
          </el-table-column>
          <el-table-column
            prop="status"
            label="采购状态"
            key="6">
            <template slot-scope="{row}">{{toStr({1:'待收货', 2:'取消', 3:'删除', 4:'部分收货',5:'全部收货'},row.status)}}</template>
          </el-table-column>
          <!-- 不支持分拣的供应商数据，不需要显示食堂名称字段 -->
          <el-table-column
            v-if="info.is_sorter == 1"
            prop="store_name"
            label="食堂名称"
            key="7">
          </el-table-column>
          <el-table-column
            prop="last_purchase_price"
            label="采购价"
            key="8">
          </el-table-column>
          <el-table-column
            prop="cov_unit"
            label="采购单位"
            width="110px"
            key="9">
            <template slot-scope="{row}">
              <!-- 没有采购单位就默认下单单位 -->
              <span>{{!!row.cov_unit ? row.cov_unit : row.unit_name}}</span>
              <!-- 如果有单位转化就显示转化 -->
              <span v-if="row.cov_times != 0">（{{row.cov_times}}{{row.unit_name}}）</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="num"
            label="下单数量"
            key="10">
          </el-table-column>
          <el-table-column
            prop="delivery_num"
            label="已收货数量"
            key="11">
          </el-table-column>
          <el-table-column
            prop="delivery_money"
            label="已收货金额"
            key="12">
          </el-table-column>
          <el-table-column
            prop="remark"
            label="备注"
            key="13">
            <template slot-scope="{row}">
              <div v-for="(v,i) in row.remark" :key="i">{{v}}。</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="refund_num"
            label="*退货数量"
            width="100px"
            key="14"
            fixed="right">
            <template slot-scope="{row,$index}">
              <!-- 已经退过货的（refund_num_bak退货数量大于0） 或者 收货数量是0的不可退货 -->
              <span v-if="row.refund_num_bak > 0 || row.delivery_num == 0">{{row.refund_num}}</span>
              <template v-else>
                <el-input v-model="row.refund_num" placeholder="请输入"  size="small" @input="numREG_EXP(row,$index)" @blur="blurNumb(row)"></el-input>
              </template>
            </template>
          </el-table-column>
          <el-table-column
            prop="refund_reason"
            label="*退货原因"
            width="180px"
            key="15"
            fixed="right">
            <template slot-scope="{row}">
              <span v-if="row.refund_num_bak > 0 || row.delivery_num == 0">{{toStr({1:'质量问题',2:'买多了/买错了',3:'商品与要求不符'},row.refund_reason)}}</span>
              <template v-else>
                <el-select v-model="row.refund_reason" size="small" >
                  <el-option label="质量问题" :value="1"></el-option>
                  <el-option label="买多了/买错了" :value="2"></el-option>
                  <el-option label="商品与要求不符" :value="3"></el-option>
                </el-select>
              </template>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="footerWrap" v-if="multipleSelection.length > 0">
        <el-button type="primary" size="medium" @click="handleConfirm">选好了</el-button>
      </div>
    </div>

    <el-dialog title="退货信息" width="800px" append-to-body :visible.sync="dialogTableVisible" center :close-on-click-modal="false">
      <el-table :data="multipleSelection" border>
        <el-table-column property="goods_name" label="商品名称"></el-table-column>
        <el-table-column property="unit_name" label="单位"></el-table-column>
        <el-table-column property="unit_name" label="规格"></el-table-column>
        <el-table-column property="delivery_num" label="已收数量"></el-table-column>
        <el-table-column property="refund_num" label="*退货数量"></el-table-column>
        <el-table-column property="refund_reason" label="*退货原因">
          <template slot-scope="{row}">
            <span>{{toStr({1:'质量问题',2:'买多了/买错了',3:'商品与要求不符'},row.refund_reason)}}</span>
          </template>
        </el-table-column>
      </el-table>
      <div style="text-align:center;margin-top:20px;">
        <el-button type="primary" size="medium" @click="confirm">确定提交</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
  import { mixinTable } from "@/mixins/table.js";
  import { getStorage } from '@/storage'
  export default {
    name: 'purchaseListEdit',
    mixins: [mixinTable],
    data() {
      return {
        token: getStorage('token'),
        subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
        visible: false,
        info: {
          is_sorter: '', // 该供应商是否支持代分拣 1：是，2否
        },
        currRow: {
          order: {}
        },
        table: {
          total:0,
          num: 0
        },
        multipleSelection: [],
        tableData: [
          {
            good_img: require('@/assets/img/lb.png'),
            good_name:'小白菜',
            num:'',
            remark: '', // 备注
          }
        ],
        storeArr: [],
        loading: false,
        form: {
          loading: false,
          data: {
            goods_name: '',
            address_id: ''
          },
          rules: {
            content: [
              { required: true, message: "请输入", trigger: "blur" },
            ],
          },
        },
        fileList: [],
        isShowDialog: false,

        pos:0,
        spanArr: [],// 合并单元格
        dialogTableVisible: false,
        
      }
    },
    methods: {
      async getDetail(row) {
        this.currRow = row
        await this.getTable();
      },
      getTable() {
        let _params = {
          id: this.currRow.id,
          goods_name: this.form.data.goods_name,
          address_id: this.form.data.address_id,
          page:1,
          count:1000
        }
        this.$http.get('/admin/purchase_order/info', { params: _params}).then(res => {
          if(res.code == 1) {
            this.info = res.data.main;
            this.table.num = res.data.main.num;
            let arr = res.data.details.list.map(v => {
              return {
                ...v,
                bind_id: v.bind_id == 0 ? '' : v.bind_id,
                refund_num: v.refund_num == 0 ? '' : v.refund_num,
                refund_num_bak: v.refund_num,
                refund_reason: v.refund_reason == 0 ? '' : v.refund_reason,
              }
            })
            this.tableData = arr;

            // 支持分拣供应商且来源为销售订单的商品去重
            if(this.info.is_sorter == 1 && this.info.source == 1) {
              let some = [];
              this.tableData.forEach(el => {
                if (!some.some(e => (e.code == el.code && e.spec_name == el.spec_name))) {
                  some.push(el);
                }
              });
              this.table.total = some.length;
            } else {
              this.table.total = res.data.details.total;
            }

            this.pos = 0; // 初始化合并单元格数据
            this.spanArr = []; // 初始化合并单元格数据
            this.getSpanArr(this.tableData)
          }
        })
      },
      reset(done) {
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 获取食堂
      getStore() {
        this.$http.get('/admin/path/storeList', {params: {page:1,count:10000}}).then(res => {
          if(res.code === 1) {
            this.storeArr = res.data.list;
          }
        })
      },
       // 批量选择数据
      handleSelectionChange(val) {
        this.multipleSelection = val;
      },
      // 选好了
      handleConfirm() {
        if(this.multipleSelection.length > 0) {
          this.dialogTableVisible = true;
        } else {
          this.$message.warning("请先勾选您要退货的数据")
        }
      },
      // 退货
      confirm() {
        let is_refund_num = this.multipleSelection.some((item) => !item.refund_num); // 检测提交的数据是否填了退货数量
        let is_refund_reason = this.multipleSelection.some((item) => !item.refund_reason); // 检测提交的数据是否选了退货原因
        if(is_refund_num == true) {
          this.$message.warning("选择的商品存在必填项未填写，请检查")
          return
        }
        if(is_refund_reason == true) {
          this.$message.warning("选择的商品存在必填项未填写，请检查")
          return
        }
        let _spec = this.multipleSelection.map(v => {
          return {
            id: v.id,
            refund_num: v.refund_num,
            refund_reason: v.refund_reason,
          }
        })
        console.log(_spec)
        let _params = {
          id: this.info.id,
          details: _spec
        }
        console.log(_params,"_params")
        // return
        this.$http.post('/admin/purchase_order/refund', _params).then(res => {
          if(res.code == 1) {
            this.dialogTableVisible = false;
            this.$message.success('操作成功！')
            this.getTable()
          }
        })
      },
      blurNumb(row) {
        if(Number(row.refund_num) < 0 || Number(row.refund_num) > Number(row.delivery_num)) {
          this.$message.warning('请填写大于0且小于等于已收数量的值')
          return;
        }
      },
      numREG_EXP(row,index) {
        const reg = /^[\d]+$/;
        // 任何单位的数量都可以输入2位小数
        if (row.refund_num && !reg.test(row.refund_num)) {
          row.refund_num = row.refund_num=row.refund_num.match(/\d+(\.\d{0,2})?/) ? row.refund_num.match(/\d+(\.\d{0,2})?/)[0] : '';
        }
      },


      selectEnable(row) {
        // 如果该商品已经退货了或者该商品收货数量为0,则该商品已退货或未收货，不支持退货
        if((row.refund_num_bak > 0) || row.delivery_num == 0) {
          return false
        } else {
          return true
        }
      },

      getSpanArr(data) {
        // data 是我们从后台拿到的数据
        for(var i = 0; i < data.length; i++) {
          if(i == 0) {
            this.spanArr.push(1);
            this.pos = 0;
          } else {
            // 判断当前元素与上一个元素是否相同 (商品编码和规格一至的需要合并)
            if((data[i].code == data[i -1].code) && (data[i].spec_name == data[i -1].spec_name) ) {
              this.spanArr[this.pos] += 1;
              this.spanArr.push(0);
            } else {
              this.spanArr.push(1);
              this.pos = i;
            }
          }
        }
      },
      // 当前行row、当前列column、当前行号rowIndex、当前列号columnIndex四个属性
      objectSpanMethod({ row, column, rowIndex, columnIndex }) {
        // 支持分拣供应商且来源为销售订单的数据合并单元格
        if(this.info.is_sorter == 1 && this.info.source == 1 ) {
          if(columnIndex < 6  && columnIndex > 0) {
            const _row = this.spanArr[rowIndex];
            const _col = _row > 0 ? 1 : 0;
            // console.log(`rowSpan:${_row}, colspan:${_col}`)
            return {
              // [0,0] 表示这一行不显示，[2,1]标示这一行的合并数
              rowspan: _row,
              colspan: _col,
            }
          }
        }
      },
      // 返回
      handleReturn() {
        this.form.data.goods_name = '';
        this.$emit("refresh")
        this.toggle(false)
      },
     
    }
  }
</script>

<style scoped lang="scss">
.addPerson__wrap {
  @include position($t: 0, $r: 0, $b: 0, $l: 0);
} 
.addPerson__wrap .main {
  overflow-x: hidden;
  background: #fff;
}
.returnBtn {
  z-index: 99;
  box-shadow: 0 0 8px 0 rgb(232 237 250 / 60%), 0 2px 4px 0 rgb(232 237 250 / 50%);
}
.footerWrap {
  text-align: center;
  padding: 10px;
   box-shadow: 0 0 8px 0 rgb(232 237 250 / 60%), 0 2px 4px 0 rgb(232 237 250 / 50%);
  z-index: 99;
}
.itemTime {
  display: block;
  margin-bottom: 10px;
  height: 30px;
  line-height: 30px;
}
.itemTime::before {
  content: "";
  width: 5px;
  height: 15px;
  background: #2cb167;
  border-radius: 6px;
  display: inline-block;
  margin-right: 10px;
  position: relative;
  top: 2px;
}
.flex {
  display: flex;
}
.mt20 {
  margin-top: 20px;
}
.mb20 {
  margin-bottom: 20px;
}
.width200px {
  width: 200px;
}
.after-table {
  padding: 10px;
  font-size: 13px;
  color: #303030;
  text-align: right;
}

::v-deep .el-table tbody tr:hover>td {
  background-color:#fff !important
}
.el-table tbody tr:hover>td {
  background-color:#fff !important
}
</style>
